import React from "react";
import './HeroRightButtons.css'

const HeroRightButtons = () => {
    return (
        <div className="HeroRight-Buttons">
            <button className="ButtonTextStyle">
            Join Now
            </button>
        </div>
        

       
    )
}

export default HeroRightButtons