import React from "react";
import './HeroButtons.css'

const HeroButtons = () => {
    return (
        <div className="Hero-Buttons">
            <button className="ButtonTextStyle">
                Get Started
            </button>

            <button className="ButtonTextStyle">
                Learn more
            </button>


        </div>

       
    )
}

export default HeroButtons