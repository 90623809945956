import React from "react";
import './ProgramsInto.css'


const Programsintro = () => {
    return (
        <div className="programsintro" id="programs">
            <span className="stoke-text">Explore </span>
            <span className="stoke">Our </span>
            <span className="stoke-text">Programs</span>
            
        </div>

       
    )
}

export default Programsintro