import React from 'react'
import './subscriptionIntro.css'


const SubscriptionIntro = () => {
    return (
        <div className="subIntro" >
            <span className="stoke-text">Subscribe </span>
            <span className="stoke">with us </span>
            <span className="stoke-text">today</span>
            
        </div>
    )
}

export default SubscriptionIntro